<template>
  <VueSelect
    :options="options"
    @search="onSearch"
    :get-option-label="getOptionLabel"
    @input="$emit('input', $event)"
    :value="value"
    :filterable="false"
    :multiple="true"
  ></VueSelect>
</template>

<script>
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import _ from 'lodash'
import config from '@/config'

export default {
  name: 'AddressSelect',
  components: {
    VueSelect
  },
  props: {
    value: {
      default: null
    }
  },
  data () {
    return {
      options: [],
      addressSearch: ''
    }
  },
  computed: {},
  methods: {
    getOptionLabel (option) {
      if (typeof option === 'object') {
        return option.name ? option.name : 'empty'
      }
      return '-'
    },
    onSearch (search, loading) {
      if (search.length > 2) {
        this.addressSearch = search
        loading(true)
        this.apiSearch(loading, search, this)
      }
    },
    apiSearch: _.debounce((loading, search, vm) => {
      vm.$axios.get(config.baseApiUrl + '/api/order-places?search=' + vm.addressSearch)
        .then((response) => {
          vm.options = response.data
        })
        .catch()
        .then(() => {
          loading(false)
        })
    }, 500)
  }
}
</script>

<style scoped>

</style>
