<template>
  <div class="container-fluid mt-3">
    <b-nav tabs justified class="d-none">
      <b-nav-item :to="'/reports/orders'" active>Orders</b-nav-item>
    </b-nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'ReportsList'
}
</script>

<style scoped>

</style>
